<template>
    <teleport to="head">
        <title>{{ txt[prefs.lang].cart }} – PLANTA</title>
    </teleport>

    <h2 v-if="!ch_order">{{ txt[prefs.lang].cart }}</h2>
    <template v-else>
        <h2 v-if="user">{{ txt[prefs.lang].order_editing }} #{{ ch_order.id }}</h2>
        <h2 v-else>{{ txt[prefs.lang].order_editing }}</h2>
    </template>
<!--    <el-button @click="test">Test</el-button>-->

    <template v-if="Object.keys(cart.items).length">

        <table class="fixed">

            <CartItem
                v-for="(item, i) in cart.items"
                :key="i"
                :item="item"
                @add="addToCart"
                @del="delFromCart"
            />

            <CartDiscounts v-if="me && me.group.id < 10 || !me" />

            <tr style="font-weight: bold;">
                <td>{{ txt[prefs.lang].total }}</td>
                <td class="hidden-xs-only" colspan="4">{{ cart.q() }} {{ txt[prefs.lang].pcs }}</td>
                <td>
                    <span v-if="cart.total() !== cart.pre_discount_total()" class="crossed">{{ cart.pre_discount_total() }}₴<br></span>
                    {{ Math.round(cart.total()*100)/100 }}₴
                </td>
                <td></td>
            </tr>

        </table>


        <p v-if="me && me.group.id >= 10 && cart.total() < settings.free_shipping_from" class="centered">⚠️ Мінімальне замовлення – {{ settings.free_shipping_from }} грн</p>


        <div v-if="cart.isOver()" class="centered">

            <h3 style="color: #f44;">* {{ title=txt[prefs.lang].unavail }}</h3>

            <el-popover
                placement="top-start"
                :title=txt[prefs.lang].reduce
                :width="250"
                trigger="hover"
                :content=txt[prefs.lang].reduce_i
            >
                <template #reference>
                    <el-button @click="cart.reduceOvers()" size="mini" plain round>
                        {{ txt[prefs.lang].reduce }}
                    </el-button>
                </template>
            </el-popover>&nbsp;

            <el-popover
                placement="top-start"
                :title=txt[prefs.lang].notify_na
                :width="250"
                trigger="hover"
                :content=txt[prefs.lang].notify_na_i
            >
                <template #reference>
                    <el-button @click="notify_na" size="mini" plain round :disabled="notified_na">
                        {{ txt[prefs.lang].notify_na }}
                    </el-button>
                </template>
            </el-popover>&nbsp;

            <el-popover
                placement="top-start"
                :title=txt[prefs.lang].notify
                :width="250"
                trigger="hover"
                :content=txt[prefs.lang].notify_i
            >
                <template #reference>
                    <el-button @click="notify" size="mini" plain round :disabled="notified">
                        {{ txt[prefs.lang].notify }}
                    </el-button>
                </template>
            </el-popover>

            <div class="chbx hidden-xs-only">
                <el-checkbox v-model="whaitavail">{{ txt[prefs.lang].whaitavail }} {{ cart.forecast() }})</el-checkbox>
            </div>
            <div class="chbx hidden-sm-and-up">
                <el-checkbox v-model="whaitavail" :label=txt[prefs.lang].whait></el-checkbox>
                <br>({{ txt[prefs.lang].whaitavail }} {{ cart.forecast() }}))
            </div>

        </div>

        <div class="centered">

            <br>
            <el-button v-if="!ch_order" type="success" @click="checkLogin(true)"
                :disabled="(cart.isOver() && !whaitavail) || cart.q() < 1 || (me && me.group.id >= 10 && cart.total() < 1)" round>{{ txt[prefs.lang].to_checkout }}</el-button>

            <el-button v-if="ch_order" @click="cancelEditing" round>{{ txt[prefs.lang].cancel_editing }}</el-button>

            <el-button v-if="ch_order" type="success" @click="changeOrder"
                :disabled="(cart.isOver() && !whaitavail) || cart.q() < 1 || (me && me.group.id >= 10 && cart.total() < 1)" round>{{ txt[prefs.lang].save }}</el-button>


            <br><br>
            <el-popconfirm
                :title=txt[prefs.lang].delete_all
                :confirmButtonText=txt[prefs.lang].delete
                :cancelButtonText=txt[prefs.lang].no_thanks
                @confirm="cart.emptyCart()"
            >
                <template #reference>
                    <el-button type="danger" size="mini" plain round>{{ txt[prefs.lang].clean_cart }}</el-button>
                </template>
            </el-popconfirm>

            <p>
                {{ txt[prefs.lang].cart_notifs }}
                <el-switch v-model="prefs.notifications"></el-switch>
            </p>

        </div>

    </template>

    <div class="centered" v-else>
        <p>{{ txt[prefs.lang].cart_is_empty }}</p>
        <router-link to="/products/all"><el-button type="success" round>{{ txt[prefs.lang].all_products }}</el-button></router-link>
    </div>

</template>


<script>
import CartItem from '@/components/CartItem'
import CartDiscounts from '@/components/CartDiscounts'
import useCart from "@/use/cart"
import {ref, watch, watchEffect, forceUpdate, inject} from 'vue'
import { txt } from '@/use/txt'
import { gql } from '@apollo/client'
import {useQuery, useMutation, useResult} from "@vue/apollo-composable"
import router from '@/router/index'
import {apolloClient} from "../main";
import productsQuery from '@/graphql/products.query.gql'
import axios from "axios"

export default {
    setup(props, context) {

        const { cart, getCartItems, message, addToCart, delFromCart, products, prefs, user, me, group, ch_order: ch_order, order_items, settings } = useCart()
        const baseUrl = inject('baseUrl')

        // const { result } = useQuery(productsQuery, {}, { fetchPolicy: "network-only", pollInterval: 10000 })
        // products.value = useResult(result, products.value, data => data.products)

        const whaitavail = ref(false)

        const notified = ref(false)
        const notified_na = ref(false)
        watch(cart.items, () => {
            notified.value = false
            notified_na.value = false
        })

        // watchEffect(() => {
        //     if (Object.keys(cart.items).length) console.log('haps') //forceUpdate()
        //     // console.log(Object.keys(cart.items).length)
        // })

        // const isOver = computed(() => !!cart.items.filter((i) => i.q > 0 && i.q > cart.availQ(i.id)).length)
        // const fd = reactive( { whaitavail: null })

        const { mutate: change_order, loading: chOrderLoading } = user.value ? useMutation(gql`
            mutation($id: ID!, $whaitavail: Boolean!, $items: String!, $total: Float!) {
                order: changeOrder_(id: $id, whaitavail: $whaitavail, items: $items, total: $total)
            }
        `) : useMutation(gql`
            mutation($id: ID!, $whaitavail: Boolean!, $items: String!, $total: Float!) {
                order: changeOrder(id: $id, whaitavail: $whaitavail, items: $items, total: $total)
            }
        `)

        function changeOrder() {
            change_order({ id: ch_order.value.hash, whaitavail: whaitavail.value, items: JSON.stringify(cart.items), total: cart.total() }).then(r => {
                    let resp = JSON.parse(r.data.order.split(/\n\r?\n\r?/)[1])
                    if (!resp.error) {
                        message(txt[prefs.lang].saved, txt[prefs.lang].all_saved, 'success')
                        cancelEditing()
                        cart.emptyCart(false)
                    }
                    else message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                }).catch(e => {
                    axios.get('/sanctum/csrf-cookie').then(() => {
                        axios.post('/api/error', {type: 5, error: e.toString()})
                    })
                    message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                })
            // cart.emptyCart()
        }

        function cancelEditing() {
            let hash = ch_order.value.hash
            localStorage.removeItem('ch_order')
            ch_order.value = null
            localStorage.removeItem('order_items')
            order_items.value = null
            // cart.emptyCart()
            router.push({path: '/order/'+hash})
        }

        const { mutate: add_notification, loading: addNotifLoading, onError } = user.value ? useMutation(gql `
            mutation($products: String!, $customer_id: ID!) {
                ntf: addNotification_(products: $products, customer_id: $customer_id) {
                    id
                }
            }`) : useMutation(gql `
            mutation($products: String!) {
                ntf: addNotification(products: $products) {
                    id
                }
            }`)

        function notify(reduce = false) {
            if (checkLogin()) {
                let items = cart.items.filter(i => i).map(i => { return i.id+':'+i.q }).join(';')
                add_notification({ products: items, customer_id: me.value.id }).then(r => {
                    if (r.data.ntf) {
                        notified.value = true
                        message(txt[prefs.lang].cart, txt[prefs.lang].notify_i, 'success')
                    }
                    else message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                }).catch(e => {
                    message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                })
            }
        }

        function notify_na() {
            if (checkLogin()) {
                // let collected = cart.collectOvers()
                add_notification({ products: cart.collectOvers(), customer_id: me.value.id }).then(r => {
                    if (r.data.ntf) {
                        notified_na.value = true
                        message(txt[prefs.lang].cart, txt[prefs.lang].notify_i, 'success')
                    }
                    else message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                }).catch(e => {
                    message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                })
            }
        }

        function checkLogin(checkout = false) {
            if (!me.value) {
                if (!user.value) context.emit('showLogin', checkout ? 'guest' : true, whaitavail.value )
                else context.emit('changeCustomer')
            }
            else if (checkout) {
                apolloClient.query({ query: productsQuery, fetchPolicy: "network-only" }).then(r => {
                    products.value = r.data.products
                    router.push({ path: '/checkout', query: { whaitavail: whaitavail.value } })
                })
            }
            else return true
        }

        return { whaitavail, notify, notify_na, notified, notified_na, cart, addToCart, delFromCart, products, prefs, me, group, txt, ch_order, cancelEditing, changeOrder, checkLogin, user, settings }
    },
    components: {
        CartItem,
        CartDiscounts
    },
    props: {
        // cartItems
    }
}

</script>


<style>
    table.fixed {table-layout:fixed; width:100%;}/*Setting the table width is important!*/
    /*table.fixed td {overflow:hidden;}*/
    table.fixed td:nth-of-type(1) {width:45%;} /*Setting the width of column 1.*/
    table.fixed td:nth-of-type(2) {width:20%;}
    table.fixed td:nth-of-type(3) {width:5%;}
    table.fixed td:nth-of-type(4) {width:10%;}
    table.fixed td:nth-of-type(5) {width:5%;}
    table.fixed td:nth-of-type(6) {width:10%;}
    table.fixed td:nth-of-type(7) {width:5%;}

    .crossed {
        color: #aaa;
        text-decoration: line-through;
    }
    .el-input .el-select {
        width: 120px;
    }
    .el-select {
        width: 250px;
    }
</style>